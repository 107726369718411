import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';

const VideoControls = ({ hangUp }) => {

    return (                        
        <div className="button-controls d-flex justify-content-center w-100">
            <button type="button" className="btn btn-danger me-2" onClick={hangUp}>
                <FontAwesomeIcon icon={faPhoneSlash} />
            </button>
        </div>
    );

}

export default VideoControls;