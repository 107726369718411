import { faClose, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Tooltip } from 'bootstrap';

const VideoCallIdShareBanner = ({callId}) => {

    const [isClosed, setIsClosed] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    const copyCallId = () => {
        setIsCopied(true);
        navigator.clipboard.writeText(callId);
    }

    let content: JSX.Element | null;
    if (!isClosed) {
        content = (
            <div className="alert-container">
                <div className="alert alert-scondary">
                    <div className="d-flex align-items-start justify-content-between w-100">
                        <div>
                            <p>Share this call ID with the person you want to call.</p>
                            <p className="mb-0">
                                <strong>Call ID:</strong> {callId} 
                                <button onClick={copyCallId} className="btn-icon" data-bs-toggle="tooltip" data-bs-placement="top" title={isCopied ? 'Copied' : 'Copy call ID'}>
                                    <FontAwesomeIcon icon={faCopy} />
                                </button>{isCopied}
                            </p>
                        </div>
                        <button type="button" className="btn btn-link" onClick={() => setIsClosed(true)}>
                            <FontAwesomeIcon icon={faClose} />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl: Element) => {
            new Tooltip(tooltipTriggerEl);
        });
    }, []);

    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl: Element) => {
            const tooltip = Tooltip.getInstance(tooltipTriggerEl);
            if (tooltip) {
                tooltip.setContent({ '.tooltip-inner': isCopied ? 'Copied' : 'Copy call ID' });
            }
        });
    }, [isCopied]);

    return (
        content
    );
}

export default VideoCallIdShareBanner;