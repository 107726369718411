import { useRef, useState } from 'react';
import { addDoc, collection, doc, DocumentReference, DocumentSnapshot, getDoc, onSnapshot, query, QuerySnapshot, setDoc } from '@firebase/firestore';
import { db } from '../firebase';
import VideoCallPrompt from './VideoCallPrompt';
import './video-chat.css';
import VideoControls from './VideoControls';
import VideoCallIdShareBanner from './VideoCallIdShareBanner';

interface VideoChatProps {
    peerConnection: RTCPeerConnection;
    localStream: MediaStream;
    remoteStream: MediaStream; 
}

function VideoChat({ peerConnection, localStream, remoteStream }: VideoChatProps) {

    const pc: RTCPeerConnection = peerConnection;
    
    let localVideoRef = useRef<HTMLVideoElement | null>(null);
    let remoteVideoRef = useRef<HTMLVideoElement | null>(null);
    
    let answerId: string;

    const [callId, setCallId] = useState<string>('');
    const [gumError, setGumError] = useState<string | null>(null);
    const [showShareBanner, setShowShareBanner] = useState<boolean>(false); 
    const [callStatus, setCallStatus] = useState<string>('');
    const [remoteStreamPlaying, setRemoteStreamPlaying] = useState<boolean>(false); 

    const startACall = () => {
        setCallStatus('started');
        setShowShareBanner(true);
        startVideo('offer');
    }

    const startVideo = async (type: string) => {

        // clear existing error message
        if (gumError) {
            setGumError('');
        }

        try {
            
            localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            localVideoRef.current!.srcObject = localStream;

            remoteStream = new MediaStream();
            if (remoteVideoRef.current) {
                remoteVideoRef.current.srcObject = remoteStream;
            }

            // pc = new RTCPeerConnection(iceServers);
            localStream.getTracks().forEach((track: MediaStreamTrack) => {
                pc.addTrack(track, localStream);
            });

            pc.ontrack = (event: RTCTrackEvent) => {
                event.streams.forEach((stream: MediaStream) => {
                    stream.getTracks().forEach((track: MediaStreamTrack) => {
                        remoteStream.addTrack(track);
                    });
                });
                if (remoteVideoRef.current) {
                    remoteVideoRef.current.srcObject = remoteStream;
                    setRemoteStreamPlaying(true);
                    setShowShareBanner(false);
                }
            };

            pc.oniceconnectionstatechange = () => {
                if (pc.iceConnectionState === 'disconnected') {
                    hangUp();
                }
            }

            if (type === 'offer') {
                createOffer();
            } else {
                answerCall();
            }

        } catch(err) {
            console.error('Error accessing media devices.', err);
            setGumError('Error accessing media devices. Please allow access to camera and microphone.');
            return;
        }

    }

    const createOffer = async () => {

        const myCollectionRef = collection(db, "calls");
        const docRef = await addDoc(myCollectionRef, {});

        setCallId(docRef.id);

        addDoc(collection(db, 'calls', docRef.id, 'offerCandidates'), {});

        pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
            if (event.candidate) {
              addDoc(collection(db, 'calls', docRef.id, 'offerCandidates'), event.candidate.toJSON());
            }
        };

        const offerDescription = await pc!.createOffer();
        await pc!.setLocalDescription(offerDescription);

        const offer = {
            sdp: offerDescription.sdp,
            type: offerDescription.type,
        };

        const callDoc = doc(db, 'calls', docRef.id);

        setDoc(callDoc, { offer }, { merge: true });

        setShowShareBanner(true);
        setCallStatus('started');

        onSnapshot(callDoc, (snapshot: DocumentSnapshot) => {
            const data = snapshot.data();
            if (!pc!.currentRemoteDescription && data?.['answer']) {
                const answerDescription = new RTCSessionDescription(data?.['answer']);
                pc!.setRemoteDescription(answerDescription);
            }
        });

        onSnapshot(query(collection(db, 'calls', docRef.id, 'answerCandidates')), (snapshot: QuerySnapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === 'added' && change.doc.data()['sdpMid']) {
                    const candidate = new RTCIceCandidate(change.doc.data());
                    pc!.addIceCandidate(candidate);
                }
            });
        });

    }

    const joinCall = (answerCallId: string) => {
        answerId = answerCallId;
        setCallStatus('started');
        setCallStatus('started');
        startVideo('answer');
    }

    const answerCall = async () => {

        const callDoc: DocumentReference = doc(db, 'calls', answerId);
        const docRef: DocumentSnapshot = await getDoc(callDoc);
        const callData = docRef.data();

        pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
          if (event.candidate) {
            addDoc(collection(db, 'calls', answerId, 'answerCandidates'), event.candidate.toJSON());
          }
        };

        const offerDescription = callData['offer'];
        await pc.setRemoteDescription(new RTCSessionDescription(offerDescription));

        const answerDescription = await pc.createAnswer();
        await pc.setLocalDescription(answerDescription);

        const answer = {
          type: answerDescription.type,
          sdp: answerDescription.sdp,
        };

        // add answer to the collection
        setDoc(callDoc, { answer }, { merge: true });

        onSnapshot(query(collection(db, 'calls', answerId, 'offerCandidates')), (snapshot: QuerySnapshot) => {
            
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added' && change.doc.data()['sdpMid']) {
              const candidate = new RTCIceCandidate(change.doc.data());
              pc.addIceCandidate(candidate);
            }
          });

        });

    }

    const hangUp = () => {

        // stop camera
        localStream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        localVideoRef.current!.srcObject = null;

        // stop remote stream
        remoteStream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        remoteVideoRef.current!.srcObject = null;
        
        // disconnect peer connection
        pc.close();

        setCallStatus('ended');
        setRemoteStreamPlaying(false);

    }

    let content: JSX.Element;
    if (callStatus === 'ended') {
        content = <div className="max-window d-flex justify-content-center align-items-center">
            <h3 className="text-info pt-5">
                <strong>Call Ended</strong>
            </h3>
        </div>;
    } else if (callStatus === 'started') {
        content = (
            <div className="videos max-window">
                <video ref={localVideoRef} className={remoteStreamPlaying ? 'thumbnail-video' : 'full-video'} autoPlay playsInline muted />
                <video ref={remoteVideoRef} className={remoteStreamPlaying ? 'full-video' : 'd-none'} autoPlay playsInline />
                {gumError && <p className="text-danger">{gumError}</p>}
                {showShareBanner && callId ? <VideoCallIdShareBanner callId={callId} /> : ''}
                <VideoControls hangUp={hangUp} />
            </div>
        )
    } else {
        content = <VideoCallPrompt startACall={startACall} joinCall={joinCall} />;
    }

    return (
        <div className="video-chat">
            {content}
        </div>
    );

};

export default VideoChat;