import { FormEvent, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { db } from '../firebase';
import { doc, getDoc } from '@firebase/firestore';

const VideoCallPrompt = ({startACall, joinCall}) => {

    const [joinError, setJoinError] = useState<string | null>(null);
    const [answerCallId, setAnswerCallId] = useState<string>('');

    const onAnswerCall = async (e: FormEvent) => {

        e.preventDefault();
        if (!answerCallId) {
            setJoinError('Please enter a call id');
            return;
        }

        const callDoc = doc(db, 'calls', answerCallId);
        const callDocRef = await getDoc(callDoc);
        const callData = callDocRef.data();
        if (!callData) {
            setJoinError('Call not found');
            return;
        }

        joinCall(answerCallId);

    }

    return (
        <div className="container d-flex justify-content-center align-items-center flex-column pt-5">
            <div className="w-100" style={{maxWidth: "300px"}}>
                <div className="d-flex flex-column align-items-center w-100 mb-5">
                    <h3 className="text-center">Start a Call</h3>
                    <Button type="button" className="btn btn-outline-primary me-2 my-3 w-100" onClick={() => startACall()}>Start Call</Button>
                </div>
                <form onSubmit={(e) => onAnswerCall(e)} className="w-100">
                    <h3 className="text-center">Join a Call</h3>
                    <div className="mt-3">
                        <FormControl type="text" placeholder="Enter call id" required onChange={(e) => setAnswerCallId(e.target.value)} className="me-2" />
                        <Button type="submit" className="btn btn-outline-primary text-nowrap mt-3 w-100" disabled={!answerCallId}>Join Call</Button>
                    </div>
                    {joinError ? <p className="text-danger mt-3">{joinError}</p> : ''}
                </form>
            </div>
        </div>
    )

}

export default VideoCallPrompt;