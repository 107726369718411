// import LoginForm from './components/login-form';
import VideoChat from './components/VideoChat';
// import { useState } from "react";
import './App.css';
// import { getAuth, onAuthStateChanged, User } from '@firebase/auth';
// import { firebaseConfig } from './firebase';
// import { initializeApp } from '@firebase/app';
import { iceServers } from './webrtc.config';

function App() {

  let peerConnection: RTCPeerConnection = new RTCPeerConnection(iceServers);

  let localStream: MediaStream;
  let remoteStream: MediaStream;

  return (
    <div className="app max-window">
      <VideoChat peerConnection={peerConnection} localStream={localStream} remoteStream={remoteStream} />
    </div>
  );
}

export default App;
